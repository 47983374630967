<template>
  <div class="d-flex flex-row">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end">
            <KTDropdown2></KTDropdown2>
          </div>
          <div class="d-flex align-items-center">
            <div
              class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
            >
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${currentUserPersonalInfo.photo})`
                }"
              ></div>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a
                class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                >{{ currentUser.fullname }}</a
              >
              <div class="text-muted"></div>
              <div class="mt-2">
<!--                <a-->
<!--                  class="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"-->
<!--                  >Chat</a-->
<!--                >-->
<!--                <a-->
<!--                  class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"-->
<!--                  >Follow</a-->
<!--                >-->
              </div>
            </div>
          </div>
          <!--end::User-->
          <!--begin::Contact-->
          <div class="py-9">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Email :</span>
              <a class="text-muted text-hover-primary">
<!--                {{ currentUser }}-->
                {{ currentUser.email ? currentUser.email : '' }}
              </a>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Phone:</span>
              <span class="text-muted">
                {{ currentUser.phone_number ? currentUser.phone_number : '' }}
              </span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <span class="font-weight-bold mr-2">Company Name:</span>
              <span class="text-muted">
                Orko Health Ltd.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTDropdown2 from "@/view/content/dropdown/Dropdown2";
import KTProfileOverview from "@/view/pages/profile/profile-comp-1/ProfileOverview";
import KTPersonalInformation from "@/view/pages/profile/profile-comp/PersonalInformation";
import KTAccountInformation from "@/view/pages/profile/profile-comp/AccountInformation";
import KTChangePassword from "@/view/pages/profile/profile-comp/ChangePassword";
import KTEmailSettings from "@/view/pages/profile/profile-comp/EmailSettings";
import axios from "axios";

export default {
  name: "custom-page",
  components: {
    KTDropdown2,
    KTProfileOverview,
    KTPersonalInformation,
    KTAccountInformation,
    KTChangePassword,
    KTEmailSettings
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);
  },
  created() {
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('api_token');
    this.$store.dispatch('auth/getUserData');
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
    currentUser: {
      get() {
        return this.$store.state.auth.userData;
      },
    },
  }
};
</script>
